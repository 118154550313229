import React from "react"
import Layout from "../components/layout"

export default function Terms() {
  return (
    <Layout>
      <h1>OsCars Terms of Use</h1>
      <article>
        OsCars is a mock car website and not registered anywhere. This website is purely for demostration and testing purposes.
        <p/>
        By using our site, you are to accept that this site is only for demostration and testing purposes and not for any commercial use.
        <br />
        If there is intention to use the site for any other purposes other than the ones stated, you must not use our sites.
    </article>
    </Layout>
  );
}